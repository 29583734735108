import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => {
  const list = [
    {
      id: "intr",
      title: "什么是 UFlow",
      content: (
        <div>
          <p>
            目标是想要管理自己，想要提高自己的人。我们提供很多个人工作和管理的工具，没有社交、一站式的管理功能。
          </p>
          <ol>
            <li>
              脑袋里塞了太多事，又不想都塞到便签里?
              待办事项梳理你每日工作和生活的，脑袋空下来享受生活和更重要的事情。
            </li>
            <li>
              想要每日阅读提高自己的见闻、技术和能力?
              我们提供集中式的订阅机制让你能够高效筛选你的注意力，而不是被公众号和抖音等娱乐内容淹没。
            </li>
            <li>
              想要分享自己的阅读、读书和高品质阅读?
              我们提供方便的收集和分享功能，让你随时把高质量内容分享给更多人。
            </li>
            <li>
              想要记录生活、想要沉淀自己？我们提供笔记功能让你沉浸自己的文字中，不受杂乱内容的侵扰。
            </li>
            <li>
              单纯按时间记录每一个小想法？我们提供时光记功能，每一句话、每一个链接、每一张图片和每一个文件都忠实记录。
            </li>
          </ol>
        </div>
      ),
    },
  ]

  return (
    <Layout>
      <SEO title="帮助" />
      <ol>
        {list.map(b => (
          <li
            key={b.id}
            css={css`
              margin-bottom: 27px;
            `}
          >
            <h2 id={b.id}>{b.title}</h2>
            <div
              css={css`
              `}
            >
              {b.content}
            </div>
          </li>
        ))}
      </ol>
    </Layout>
  )
}

export default SecondPage
